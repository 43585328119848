html,
body {
  height: 100%;
}

.content {
  min-height: 100%;
  margin-bottom: -60px; /* Negative value of footer height */
}

.about-page {
  font-family: "Arial, sans-serif";
  text-align: center;
  padding: 20px;
  margin: 10px 250px 30px 250px;
}

.header {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-width: 400px;
}

.header img {
  width: 400px; /* Adjust this value to change the size of the image */
  max-width: 100%;
}

.about h1 {
  font-size: 3em;
  margin: 20px 0;
  clear: both;
}

.header p {
  font-size: 1.2em;
  color: #6c757d;
}

.morgan-img {
  width: 400px;

  min-width: 200px;
}

.about-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

/* Media query for screens smaller than 800px */
@media (max-width: 800px) {
  .header {
    flex-direction: column; /* Stack the logo and hamburger menu vertically */
    align-items: center; /* Center the elements vertically */
    justify-content: center; /* Center the elements horizontally */
  }
  .hamburger-menu {
    position: static; /* Override the position property */
    float: none; /* Override the float property */
  }
  .header .hamburger-icon {
    position: static; /* Override the position property */
  }
  .header img {
    width: 70%; /* Make the image take up the full width of the header */
  }

  .morgan-img {
    width: 100%; /* Make the image take up the full width of its container */
  }

  .about-page {
    margin: 10px 20px 30px 20px; /* Reduce the left and right margins */
  }

  .about-info {
    width: 80%; /* Increase the width of the about-info container */
  }
}
