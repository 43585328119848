.header {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Make sure the header is relatively positioned */
  z-index: 0; /* Set z-index to 0 to ensure dropdown menu appears over the header */
}

.dropdown-menu {
  position: absolute;
  top: 64%; /* Adjust this value as needed */
  right: 0;
  display: none; /* Hide the menu by default */
  flex-direction: column; /* Stack the items vertically */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  z-index: 1; /* Make the dropdown menu appear over the header */
}

.dropdown-menu.show {
  display: flex; /* Show the menu when the .show class is added */
}

.dropdown-menu a {
  display: block;
  padding: 10px;
  color: #000000;
  text-decoration: none;
}

.header .hamburger-icon {
  padding: 0;
  vertical-align: middle;
}

.header .dropdown-button {
  font-size: 25px !important;
  line-height: 1; /* Match line-height to font-size to remove extra space */
}

.dropdown-button {
  position: relative;
}
.active-link {
  color: red; /* Change the font color of the active link to red */
}
