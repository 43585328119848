html,
body {
  height: 100%;
}

.content {
  min-height: 100%;
  margin-bottom: -60px; /* Negative value of footer height */
}

.home-page {
  font-family: "Arial, sans-serif";
  text-align: center;
  padding: 20px;
}

.header {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.header img {
  width: 400px; /* Adjust this value to change the size of the image */
  max-width: 100%;
}

.header h1 {
  font-size: 3em;
  margin: 20px 0;
  clear: both;
}

.header p {
  font-size: 1.2em;
  color: #6c757d;
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 40px;
}

.feature {
  flex: 1 0 100px; /* Adjust this value based on min width you want for the .feature divs*/
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 50%;
}

.feature h2 {
  font-size: 2em;
  margin-bottom: 10px;
  padding-left: 125px;
  padding-right: 125px;
}

.feature p {
  color: #6c757d;
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.intro p {
  font-size: 1em;
}

.intro h2 {
  font-size: 40px;
  font-weight: bold;
  margin: 3px 0;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 400px;
}

.image-container img {
  /* display: block;
  height: 100%;
  position: relative;
  z-index: 1; */
  max-width: 100%;
  /* width: 80%; Adjust this value as needed */
  height: 400px; /* Adjust this value as needed */
  object-fit: cover;
}

.image-container:hover img {
  opacity: 0;
}

.image-container p {
  visibility: hidden;
  text-align: center;
  margin: 0;
  width: 75%;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 1em;
  text-align: center;
}

.image-container:hover p {
  visibility: visible;
}

.getInvolved {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
}

.getInvolved button {
  margin-top: 60px;
}

.moveDown {
  margin-top: 40px;
}
.footer {
  width: 100%;
  background: #f8f9fa;
  color: #292727;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  margin-top: 60px;
  font-size: 15px;
}

.getInvolved .button {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #007bff;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.getInvolved .button:hover {
  background-color: #0056b3;
}
